export const mapURL = (pageType, internalTitle) => {
  switch (pageType) {
    case "bike-page":
      return `/products/${internalTitle}`
    case "collection":
      return `/collections/${internalTitle}`
    default:
      return "/404"
  }
}

export const formatCheckoutURL = (checkoutURL: string) => {
  if (!checkoutURL) return '';
  
  try {
    const url = new URL(checkoutURL);
    
    // Check if this is a cart URL (new format)
    if (url.pathname.includes('/cart/c/')) {
      // Simply replace www with shop in the domain
      return checkoutURL.replace('www.sixthreezero.com', 'shop.sixthreezero.com');
    }
    
    // Otherwise, this is the old format, just do domain replacement
    return process.env.GATSBY_SHOPIFY_SHOP_DOMAIN &&
      process.env.GATSBY_SHOPIFY_SHOP_CUSTOM_DOMAIN
      ? checkoutURL?.replace(
          process.env.GATSBY_SHOPIFY_SHOP_DOMAIN,
          process.env.GATSBY_SHOPIFY_SHOP_CUSTOM_DOMAIN
        )
      : checkoutURL
  } catch (error) {
    console.error('Error parsing checkout URL:', error);
    return checkoutURL; // Return original URL if there's an error
  }
}
