import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Icon,
  IModal,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/core"
import { Link } from "gatsby"
import { FluidObject } from "gatsby-image"
import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { StringParam, useQueryParam } from "use-query-params"
import {
  getFixedPrice,
  getFixedPriceFromValue,
} from "../../components/Cart/utils"
import useGoogleAnalytics from "../../components/GoogleAnalytics/hooks/useGoogleAnalytics"
import { trackAddToCart } from "../../components/Scripts/EventTracker"
import useShopifyVariantPricing from "../../hooks/useShopifyVariantPricing"
import { CartItem } from "../../redux/models/checkout"
import { BaseDispatch, BaseRootState } from "../../redux/store"
import { decodeShopifyId, placeholderImage } from "../../utils"
import { bp, useLaptopUpQuery } from "../../utils/MediaQueries"
import { getPreorderLabel, useFetchPreorderInfo } from "../../utils/preorder"
import { formatCheckoutURL } from "../../utils/url"
import BackgroundImage from "../BackgroundImage"
import { Button } from "../Buttons"
import ModalCloseButton from "../Buttons/ModalCloseButton"
import FormattedTitle from "../FormattedTitle"
import Body from "../typography/Body"
import Heading from "../typography/Heading"
import SmallCaps from "../typography/SmallCaps"
import CartModelAddons from "./CartModelAddons"

const Check = () => (
  <Flex
    size="2rem"
    backgroundColor="daylight"
    borderRadius="0.5rem"
    align="center"
    justify="center"
    mr="0.9331rem"
  >
    <Icon name="check" color="white" w="0.5625rem" pt="0.0625rem" />
  </Flex>
)

const Title = (props: FlexProps) => (
  <Flex align="center" {...props}>
    <Check />
    <SmallCaps size="lg" fontWeight="bold" color="daylight">
      {props.children}
    </SmallCaps>
  </Flex>
)

const ButtonContainer = (props: BoxProps) => (
  <Box p={bp("0px", "2rem 2rem 2rem 2rem")} {...props} />
)

type AddedToCartModalProps = Omit<IModal, "children"> & {
  modalTitle?: string
  image?: FluidObject | null
  formattedTitle: string
  details: React.ReactNode
  price: string
  quantity?: number
  webUrl?: string
  bike?: any
  cartAddons?: any
}

export type Accessory = {
  contentful_id: string
  shopifyId: string
  internalTitle: string
  productListImage: any
  title: string
  topLevelImages: any
  type: string
  variants: any
  pricing: any
}

export default function AddedToCartModal({
  modalTitle = "Added to Cart!",
  image,
  formattedTitle,
  details,
  price,
  onClose,
  quantity = 0,
  webUrl,
  bike,
  cartAddons,
  ...props
}: AddedToCartModalProps) {
  const isDesktop = useLaptopUpQuery()

  const [selectedAccessories, setSelectedAccessories] = React.useState<
    Accessory[]
  >([])

  const onAccessoryChange = (accessory: Accessory) => {
    const isAccessoryPresent = selectedAccessories?.find(
      (acc) => acc?.contentful_id === accessory?.contentful_id
    )
    if (isAccessoryPresent) {
      setSelectedAccessories(
        selectedAccessories?.filter(
          (acc) => acc?.contentful_id !== accessory?.contentful_id
        )
      )
    } else {
      setSelectedAccessories([...selectedAccessories, accessory])
    }
  }

  const [hasWarranty, setHasWarranty] = React.useState(false)
  const [addedToCart, setAddedToCart] = React.useState(false)

  // Redux
  const {
    isSideBarOpen,
    isCartLoading,
    warranty,
    bodyFit,
    bikeResults,
    answers,
    previousQuizAnswers,
  } = useSelector((state: BaseRootState) => ({
    isSideBarOpen: state.sidebar.isOpen,
    isCartLoading: state.checkout.isLoading,
    warranty: state.bikes.warranty,
    bodyFit: state.user.bodyFit,
    bikeResults: state.bikes.bikeResults,
    answers: state.quiz.answers,
    previousQuizAnswers: state?.quiz?.previousQuizAnswers,
  }))
  const dispatch = useDispatch<BaseDispatch>()

  // Calculate bike results if quiz taken
  React.useEffect(() => {
    if (bikeResults === null && answers.date !== "") {
      dispatch.bikes.calculateResults()
    }
  }, [bikeResults, answers])

  const matchPercent = bikeResults?.find(
    (result) => bike?.internalTitle === result?.internalTitle
  )?.score

  // Pricing
  const {
    data: pricingData,
    pricingMap,
    loading,
  } = useShopifyVariantPricing([
    ...(bike?.variants || [])?.map((v) => v?.shopifyId),
    warranty?.shopifyId,
  ])

  // Variants
  const [
    _uniqueProductId = decodeShopifyId(bike?.variants?.[0]?.shopifyId ?? null),
    setUniqueProductId,
  ] = useQueryParam("variant", StringParam)
  const uniqueProductId =
    _uniqueProductId === ""
      ? decodeShopifyId(bike?.variants?.[0]?.shopifyId ?? null)
      : _uniqueProductId
  const variant = bike?.variants?.find(
    (v) => decodeShopifyId(v?.shopifyId) === uniqueProductId
  )

  const shopifyId = variant?.shopifyId ?? ""
  const pricing = pricingMap[shopifyId]
  const priceAmount = pricing?.priceV2.amount
  const warrantyPriceAmount = pricingMap[warranty?.shopifyId!]?.priceV2.amount
  const priceValue = Number(priceAmount || -1)
  const warrantyPriceValue = Number(warrantyPriceAmount || 0)
  const warrantyPrice = getFixedPrice(warrantyPriceAmount)
  const compareAtPrice = pricing?.compareAtPriceV2?.amount
    ? getFixedPrice(pricing.compareAtPriceV2.amount)
    : undefined
  const totalPriceValue =
    priceValue >= 0 ? priceValue + (hasWarranty ? warrantyPriceValue : 0) : -1
  const totalPrice = getFixedPriceFromValue(
    totalPriceValue >= 0 ? totalPriceValue : undefined
  )
  const bikeType = new Set(bike?.type).has("Electric")
    ? "Electric Bicycle"
    : "Bicycle"

  // Preorder info
  const productShopifyId = pricing?.product.id
  const {
    data: preorderInfo,
    error: preorderInfoError,
    loading: preorderInfoLoading,
  } = useFetchPreorderInfo([])
  const preorderLabel = getPreorderLabel(
    preorderInfo?.data,
    uniqueProductId,
    productShopifyId,
    false
  )

  const isAvailable =
    pricing && pricing.availableForSale && !pricing.currentlyNotInStock
  const isPreorder =
    pricing && pricing.availableForSale && pricing.currentlyNotInStock

  // Google Analytics Added Warranty
  const warrantyGA = useGoogleAnalytics({
    category: "Warranty",
    action: "Added Product",
    shouldFireOnFirstRender: false,
  })
  const addBicycleToCartGA = useGoogleAnalytics({
    category: "Bicycle",
    action: "Added Product",
    shouldFireOnFirstRender: false,
  })

  const onAddToCart = () => {
    const childCartItems: CartItem[] = []
    if (hasWarranty) {
      childCartItems.push({
        type: "warranty",
        productSlug: warranty?.accessory_model?.[0]?.internalTitle,
        contentfulProductId: warranty?.accessory_model?.[0]?.contentful_id,
        contentfulVariantId: warranty?.contentful_id,
        variantId: warranty?.shopifyId,
        parentBundleId: variant?.shopifyId,
      } as CartItem)
      warrantyGA.fireEvent()
      trackAddToCart({
        productId: decodeShopifyId(warranty?.shopifyId) || "",
        productName: warranty?.title || "Forever Warranty",
        productType: "Warranty",
        value: warrantyPriceAmount,
      })
    }

    addBicycleToCartGA.fireEvent()

    let itemList: { [cartItem: string]: {} }[] = []

    if (Array.isArray(selectedAccessories) && selectedAccessories.length > 0) {
      selectedAccessories.map((upsellItem) => {
        itemList.push({
          cartItem: {
            type: "accessory",
            productSlug: upsellItem?.internalTitle,
            contentfulProductId: upsellItem?.contentful_id,
            contentfulVariantId: upsellItem?.contentful_id,
            variantId: upsellItem?.shopifyId,
            isPreorder,
            preorderInfo: preorderLabel,
          } as CartItem,
          // childCartItems: [],
        })

        const value = upsellItem?.pricing?.priceV2?.amount || 0
        trackAddToCart({
          productId: decodeShopifyId(upsellItem?.shopifyId) || "",
          productName: upsellItem?.title || "",
          productType: "Accessory",
          value,
        })
      })
    }
    dispatch.checkout.addToCartBulk(itemList)

    // dispatch.checkout.addToCart(item)
    setAddedToCart(true)
    trackAddToCart({
      productId: decodeShopifyId(variant?.shopifyId) || "",
      productName: bike?.title,
      productType: bikeType,
      value: priceAmount,
    })
  }

  React.useEffect(() => {
    if (addedToCart && isCartLoading === false) {
      // Unsellect Addons / Empty Selected Array
      setSelectedAccessories([])
    }
  }, [isCartLoading])

  return (
    <Modal size="57.7687rem" onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent
        borderRadius="0.5rem"
        boxShadow="big"
        top={!isDesktop ? 0 : null}
        marginTop={!isDesktop ? 0 : null}
        borderTopLeftRadius={!isDesktop ? 0 : null}
        borderTopRightRadius={!isDesktop ? 0 : null}
      >
        <ModalCloseButton zIndex={1} />
        <ModalHeader d={bp("flex", "none")}>
          <Title>{modalTitle}</Title>
        </ModalHeader>
        <ModalBody p={0}>
          <Flex>
            <BackgroundImage
              fluid={image || placeholderImage}
              height={bp("16.75rem", "31.25rem")}
              flexGrow={1}
              backgroundSize="138%"
              backgroundPosition="100% 50%"
              backgroundRepeat="no-repeat"
            />
            <Flex
              flex={1}
              direction="column"
              justify="center"
              pl={bp("1rem", "5rem")}
            >
              <Title display={bp("none", "flex")} mb="1.5525rem">
                {modalTitle}
              </Title>
              <Heading as="h2" size="3" mb="1.6963rem">
                <FormattedTitle raw={formattedTitle} />
              </Heading>
              <Body size="md" color="dawn" mb="1.4919rem">
                {details}
              </Body>
              <Body size="md" fontWeight="semibold" color="dusk">
                {price}
              </Body>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid"
          borderColor="dividerLine"
          p={bp("1.4475rem 1.25rem 1.25rem", 0)}
          flexDirection="column"
        >
          <Flex w="100%" direction={bp("column-reverse", "row")}>
            <ButtonContainer
              borderRight="1px solid"
              borderColor={bp("transparent", "dividerLine")}
            >
              <Button
                theme="tertiary"
                onClick={onClose as VoidFunction}
                w={bp("100%", "auto")}
                borderWidth={bp("0px", "1px")}
              >
                Continue Shopping
              </Button>
            </ButtonContainer>
            <ButtonContainer display="flex" flex={1} mb={bp("0.875rem", "0")}>
              <Box
                as={Link}
                // @ts-ignore
                to="/cart"
                flex={1}
                mr={bp("1.25rem", "1.5562rem")}
              >
                <Button theme="secondary" w="100%" p={bp(0)}>
                  View Cart {quantity > 0 && `(${quantity})`}
                </Button>
              </Box>
              { previousQuizAnswers.length && (Date.now() - parseInt(previousQuizAnswers[0]?.date) > 86400000) && (
                <Box
                  as="a"
                  // @ts-ignore
                  href={webUrl}
                  flex={1}
                >
              
                  <Button theme="primary" w="100%" p={bp(0)}>
                    Checkout
                  </Button>
                
                </Box>
              )}
            </ButtonContainer>
          </Flex>
          <Flex
            borderTop="1px solid"
            borderColor="dividerLine"
            flexDirection="column"
          >
            {cartAddons && (
              <CartModelAddons
                onAddToCart={onAddToCart}
                cartAddons={cartAddons}
                selectedAccessories={selectedAccessories}
                onAccessoryChange={onAccessoryChange}
                isCartLoading={isCartLoading}
              />
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export function ConnectedAddedToCartModal(props: AddedToCartModalProps) {
  const { quantity, webUrl } = useSelector((state: BaseRootState) => ({
    webUrl: state.checkout.data?.webUrl,
    quantity: state.checkout.quantity,
  }))
  return (
    <AddedToCartModal
      {...props}
      quantity={quantity}
      webUrl={formatCheckoutURL(webUrl || '')}
    />
  )
}
