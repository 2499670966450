import * as R from "ramda"
import {
  Maybe,
  ContentfulProductCustomizerOptionsJsonNodeAccessoryCategories as ProductCustomizerAccessoryCategories,
  ContentfulBicycleModelSharedCustomizerOptionsJsonNodeAccessoryCategories as SharedCustomizerAccessoryCategories,
  ContentfulProductVariant,
  CustomizerPageQuery,
  ContentfulProduct,
} from "../../graphql-types"
import { GroupedCustomizedLineItem } from "../components/Cart/utils"
import { CheckoutLineItemFieldsType } from "../shopify/graphql/fragments"
import { CustomizerBikeType } from "../redux/types/BikeTypes"
import {stringToBase64} from "../utils/text"


export function getCustomizerColor(bike: CustomizerBikeType) {
  const colorInternalTitle = bike?.variant?.color?.internalTitle
  return colorInternalTitle
    ? bike.speed?.customizerOptions?.colors?.find(
        (color) => color?.variant?.color?.internalTitle === colorInternalTitle
      )
    : null
}

/**
 * This combines the shared customizer categories with the product's specific categories.
 *
 * It's job is to merge both categories into one list of categories that favors the specific settings over the shared
 */
export function mergeCustomizerCategories(
  sharedCategories: Maybe<SharedCustomizerAccessoryCategories>[] | null = [],
  specificCategories: Maybe<ProductCustomizerAccessoryCategories>[] | null = []
) {
  const sharedCategoriesMap = R.indexBy(
    R.prop<string>("name"),
    (sharedCategories as { name: string }[]) || []
  )
  const specificCategoriesMap = R.indexBy(
    R.prop<string>("name"),
    (specificCategories as { name: string }[]) || []
  )
  return Object.values(
    R.mergeWith(
      (sharedCategory, specificCategory) => {
        const sharedAccessoriesMap = R.indexBy(
          R.prop("variantId"),
          sharedCategory.accessories
        )
        const specificAccessoriesMap = R.indexBy(
          R.prop("variantId"),
          specificCategory.accessories
        )
        return {
          ...R.mergeRight(sharedCategory, specificCategory),
          accessories: Object.values(
            R.mergeRight(sharedAccessoriesMap, specificAccessoriesMap)
          ),
        }
      },
      sharedCategoriesMap,
      specificCategoriesMap
    )
  ) as typeof sharedCategories
}

export const transformAccessoriesFromCartItems = (
  editingCartItem: GroupedCustomizedLineItem<any>,
  bike: CustomizerPageQuery["bike"],
  speed: Partial<ContentfulProduct>
) => {
  return editingCartItem.accessories.reduce((acc, cartAccessory) => {
    // Try to find the category and corresponding varient
    let category: string | undefined
    let accessory: ContentfulProductVariant | undefined
    const variantId = stringToBase64(cartAccessory.merchandise?.id || '');
    
    bike?.sharedCustomizerOptions?.accessoryCategories?.some(
      (categoryConfig) => {
        return categoryConfig?.accessories?.some((accessoryConfig) => {
          if (
            accessoryConfig?.variant?.shopifyId === variantId
          ) {
            category = categoryConfig.name!
            accessory = accessoryConfig?.variant as ContentfulProductVariant
            return true
          }

          return false
        })
      }
    )

    if (!category && !accessory) {
      speed?.customizerOptions?.accessoryCategories?.some((categoryConfig) => {
        return categoryConfig?.accessories?.some((accessoryConfig) => {
          if (
            accessoryConfig?.variant?.shopifyId === variantId
          ) {
            category = categoryConfig.name!
            accessory = accessoryConfig?.variant as ContentfulProductVariant
            return true
          }

          return false
        })
      })
    }

    if (category && accessory) {
      return { ...acc, [category]: accessory }
    }
    return acc
  }, {})
}
