import { ModelConfig } from "@rematch/core"
import DefaultClient from "apollo-boost"
import * as R from "ramda"
import { v4 as uuid } from "uuid"
import { stringToBase64, isBase64 } from "../../utils/text"
import * as Storefront from "shopify-storefront-api-typings"
import createShopifyClient from "../../shopify/graphql/createClient"
import createShopifyClientAdmin from "../../shopify/graphql/createClientAdmin"
import { 
  CheckoutLineItemFieldsType,
  CartFieldsType
} from "../../shopify/graphql/fragments"
import {
  ApplyDiscount,
  createDiscount,
  CreateCheckout,
  CreateCheckoutResponse,
  RemoveDiscount,
  UpdateCheckout,
  UpdateCheckoutResponse,
  UpdateCheckoutEmailResponse,
  UpdateCheckoutEmail,
  CartLinesUpdate,
  CartLinesUpdateResponse,
  CartBuyerIdentityUpdate,
  CartBuyerIdentityUpdateResponse,
  CartDiscountCodesUpdate,
  CartDiscountCodesUpdateResponse,
  CartLinesAdd,
  CartLinesAddResponse,
} from "../../shopify/graphql/mutations"
import { getIn } from "../../utils"
import { BaseRootState } from "../store"

export interface CheckoutUserError {
  code: string
  field: Array<string>
  message: string
}

export type CheckoutLineItem = CheckoutLineItemFieldsType

export interface CheckoutPricing {
  amount: string
  currencyCode: string
}

export type CheckoutData = CreateCheckoutResponse["cartCreate"]["cart"]

export interface CheckoutState {
  checkoutId: string | null
  data: CheckoutData | null
  quantity: number
  isLoading: boolean
  errors: Array<CheckoutUserError> | []
  lastQueryTimestamp: number | null
}

const InitialState: CheckoutState = {
  checkoutId: null,
  data: null,
  quantity: 0,
  isLoading: false,
  errors: [],
  lastQueryTimestamp: null,
}

export interface CartItem {
  productSlug: string
  type: "bike" | "accessory" | "warranty"
  contentfulProductId?: string
  contentfulVariantId?: string
  bundleId?: string
  parentBundleId?: string
  variantId: string
  isPreorder?: boolean
  preorderInfo?: string
  quantity: number
}

// Need to tag checkout items with contentful information,
// since some product info is stored there
export const createCheckoutLineItem = ({
  type,
  productSlug,
  contentfulProductId,
  contentfulVariantId,
  bundleId,
  parentBundleId,
  variantId,
  isPreorder,
  preorderInfo,
  quantity,
}: CartItem) => {
  const attributes: Storefront.AttributeInput[] = contentfulProductId
    ? [
        { key: "_type", value: type },
        { key: "_contentfulProductId", value: contentfulProductId },
        { key: "_contentfulVariantId", value: contentfulVariantId! },
        { key: "_productSlug", value: productSlug },
        { key: "_variantId", value: variantId },
      ]
    : [
        { key: "_type", value: type },
        { key: "_productSlug", value: productSlug },
        { key: "_variantId", value: variantId },
      ]

  if (bundleId) {
    attributes.push({
      key: "_bundleId",
      value: bundleId,
    })
  }

  if (parentBundleId) {
    attributes.push({
      key: "_parentBundleId",
      value: parentBundleId,
    })
  }

  if (isPreorder) {
    attributes.push({
      key: "_isPreorder",
      value: isPreorder.toString(),
    })
    attributes.push({
      key: "_preorderInfo",
      value: preorderInfo || "",
    })
  }

  return {
    attributes,
    merchandiseId: variantId,
    quantity,
  }
}

export const mapToLineItemInput = ({ node }: { node: any }) => {
  const merchandiseId = getIn(node, "merchandise.id") || getIn(node, "variant.id");
  const id = getIn(node, "id");
  return {
    id,
    merchandiseId: ensureCorrectIdFormat(merchandiseId),
    quantity: getIn(node, "quantity"),
    attributes: R.map((attribute) =>
      R.pick(["key", "value"], attribute)
    )(getIn(node, "attributes") || getIn(node, "customAttributes") || []),
  }
}

// Returns two lists, first list contains changedLineItem matching variantId, second is unchangedLineItems
const getPartitionedLineItems = (
  lineItems: Array<{ node: CheckoutLineItem }>,
  variantIds: string[]
) =>
  R.pipe(
    R.map(mapToLineItemInput),
    R.partition(({ merchandiseId }) =>
      variantIds.includes(merchandiseId)
    )
  )(lineItems)

// Get line item from variantId
const getCurrentLineItem = (
  variantId: string,
  lineItems: Array<{ node: any }>
) =>
  R.find(
    (item: { node: any }) =>
      getIn(item, "node.merchandise.id") === variantId || 
      getIn(item, "node.variant.id") === variantId
  )(lineItems)

// keys are pre-pended with _ to hide in shopify store
const getCleanedCustomAttributeKey = (key: string) => R.replace(/^\_/, "", key)

export const getNormalizedCustomAttributes = (
  attributes: Storefront.Attribute[] | undefined
) => {
  return R.pipe(
    R.map((attribute: Storefront.Attribute) => [
      [getCleanedCustomAttributeKey(getIn(attribute, "key"))],
      getIn(attribute, "value"),
    ]),
    R.fromPairs
  )(attributes || [])
}

// Utility function to ensure IDs are in the correct format
const ensureCorrectIdFormat = (id: string): string => {
  // If already in gid:// format, return as is
  if (id.startsWith('gid://')) {
    return id;
  }
  
  // If it's a base64 encoded value, try to decode and return
  try {
    const decoded = atob(id);
    if (decoded.startsWith('gid://')) {
      return decoded;
    }
  } catch (e) {
    // Not a valid base64 string, continue
  }
  
  // Return the original ID if all else fails
  return id;
};

// Helper to create CartLineUpdateInput from cart line
const mapToCartLineUpdateInput = (item: any) => {
  if (item.id) {
    return {
      id: ensureCorrectIdFormat(item.id),
      quantity: item.quantity,
      attributes: item.attributes
    };
  }
  
  return {
    merchandiseId: ensureCorrectIdFormat(item.merchandiseId || item.variantId),
    quantity: item.quantity,
    attributes: item.attributes || item.customAttributes
  };
};

export const checkout: ModelConfig<CheckoutState> = {
  state: InitialState,
  reducers: {
    updateCheckout(state, data: CheckoutData) {
      // For backward compatibility, map the new cart fields to checkout fields
      const normalizedData = {
        ...data,
        webUrl: data.checkoutUrl,
        lineItems: data.lines,
        lineItemsSubtotalPrice: data.cost?.subtotalAmount,
        subtotalPriceV2: data.cost?.subtotalAmount,
        totalPriceV2: data.cost?.totalAmount,
        totalTaxV2: data.cost?.totalTaxAmount,
        // Normalize any missing fields to avoid undefined errors
        cost: data.cost || {
          subtotalAmount: { amount: "0", currencyCode: "USD" },
          totalAmount: { amount: "0", currencyCode: "USD" },
          totalTaxAmount: { amount: "0", currencyCode: "USD" },
        },
        lines: data.lines || { edges: [] },
        buyerIdentity: data.buyerIdentity || { email: null },
        discountCodes: data.discountCodes || [],
      };
      
      return {
        ...state,
        checkoutId: normalizedData.id,
        data: normalizedData,
        lastQueryTimestamp: Date.now(),
      }
    },
    clearCheckout(state) {
      return {
        ...state,
        checkoutId: null,
        data: {} as CheckoutData,
        quantity: 0,
      }
    },
    updateQuantity(state, quantity: number) {
      return {
        ...state,
        quantity,
      }
    },
    setIsLoading(state, isLoading: boolean) {
      return {
        ...state,
        isLoading,
      }
    },
    setErrors(state, errors: Array<CheckoutUserError>) {
      return {
        ...state,
        errors,
      }
    },
  },
  effects: (dispatch: any) => ({
    async createCheckout(
      {
        cartItem,
        childCartItems = [],
      }: {
        cartItem: CartItem
        childCartItems?: CartItem[]
      },
      state: BaseRootState
    ) {
      const client: DefaultClient<any> = createShopifyClient()

      dispatch.checkout.setIsLoading(true)
      const bundleId = childCartItems.length > 0 ? uuid() : undefined
      const lineItems = [
        createCheckoutLineItem({
          ...cartItem,
          bundleId,
        }),
        ...childCartItems.map((item) =>
          createCheckoutLineItem({
            ...item,
            parentBundleId: bundleId,
          })
        ),
      ]
      const { data, errors } = await client.mutate<CreateCheckoutResponse>({
        mutation: CreateCheckout,
        variables: {
          input: {
            lines: lineItems,
            buyerIdentity: state.user.email ? { email: state.user.email } : undefined,
          }
        },
      })
      dispatch.checkout.setIsLoading(false)

      if (errors) {
        console.error("Create checkout errors:", errors)
        dispatch.checkout.setErrors(errors)
        return
      }

      if (data?.cartCreate?.userErrors?.length) {
        console.error("Create checkout user errors:", data.cartCreate.userErrors)
        dispatch.checkout.setErrors(data.cartCreate.userErrors)
        return
      }

      if (data?.cartCreate?.cart) {
        dispatch.checkout.updateCheckout(data.cartCreate.cart)
        
        // Handle quantity
        const quantity = R.pipe(
          R.pathOr([], ["cartCreate", "cart", "lines", "edges"]),
          R.reduce(
            (acc: number, { node }: any) => acc + (node.quantity || 0),
            0
          )
        )(data)
        dispatch.checkout.updateQuantity(quantity)

        return data.cartCreate.cart
      }
      
      return null
    },

    async createCheckoutBulk(
      payload: { cartItem: CartItem; childCartItems: CartItem[] }[],
      state: BaseRootState
    ) {
      const client: DefaultClient<any> = createShopifyClient()

      dispatch.checkout.setIsLoading(true)
      const lineItems: any[] = []
      payload.forEach(
        ({
          cartItem,
          childCartItems,
        }: {
          cartItem: CartItem
          childCartItems: CartItem[]
        }) => {
          const bundleId = childCartItems.length > 0 ? uuid() : undefined
          lineItems.push(
            createCheckoutLineItem({
              ...cartItem,
              bundleId,
            }),
            ...childCartItems.map((item) =>
              createCheckoutLineItem({
                ...item,
                parentBundleId: bundleId,
              })
            )
          )
        }
      )
      const { data, errors } = await client.mutate<CreateCheckoutResponse>({
        mutation: CreateCheckout,
        variables: {
          input: {
            lines: lineItems,
            buyerIdentity: state.user.email ? { email: state.user.email } : undefined,
          }
        },
      })
      dispatch.checkout.setIsLoading(false)

      if (errors) {
        console.error("Create checkout bulk errors:", errors)
        dispatch.checkout.setErrors(errors)
        return
      }

      if (data?.cartCreate?.userErrors?.length) {
        console.error("Create checkout bulk user errors:", data.cartCreate.userErrors)
        dispatch.checkout.setErrors(data.cartCreate.userErrors)
        return
      }

      if (data?.cartCreate?.cart) {
        dispatch.checkout.updateCheckout(data.cartCreate.cart)
        return data.cartCreate.cart
      }
      
      return null
    },

    updateCheckout(checkout: CheckoutData) {
      // TODO: Fetch contentful products here
      if(checkout !== null) {
        checkout.id = stringToBase64(checkout.id);
      }

      if(checkout != null && checkout.lineItems != null) {
        checkout.lineItems.edges.map((checkoutLineItemEdge) => {
          checkoutLineItemEdge.node.id = stringToBase64(checkoutLineItemEdge.node.id);
          if(checkoutLineItemEdge.node.variant) {
            checkoutLineItemEdge.node.variant.id = stringToBase64(checkoutLineItemEdge.node.variant.id);
            if (checkoutLineItemEdge.node.variant.product) {
              checkoutLineItemEdge.node.variant.product.id = stringToBase64(checkoutLineItemEdge.node.variant.product.id);
            }
          }
          return checkoutLineItemEdge;
        })
        
      }
      const nodes = getIn(checkout, "lines.edges", [])
      dispatch.checkout.updateQuantity(
        nodes.reduce((accumulator: number, item: any) => {
          return accumulator + item.node.quantity
        }, 0)
      )
    },

    async updateCheckoutEmail(email: string, state: BaseRootState) {
      if (!email) return

      const client: DefaultClient<any> = createShopifyClient()
      try {
        dispatch.checkout.setIsLoading(true)
        const { data, errors } = await client.mutate<CartBuyerIdentityUpdateResponse>({
          mutation: CartBuyerIdentityUpdate,
          variables: {
            cartId: state.checkout.checkoutId,
            buyerIdentity: {
              email,
            },
          },
        })

        if (errors) {
          console.error("Update buyer identity errors:", errors)
          dispatch.checkout.setErrors(errors)
          return
        }

        if (data?.cartBuyerIdentityUpdate?.userErrors?.length) {
          console.error("Cart buyer identity update errors:", data.cartBuyerIdentityUpdate.userErrors)
          dispatch.checkout.setErrors(data.cartBuyerIdentityUpdate.userErrors)
          return
        }

        if (data?.cartBuyerIdentityUpdate?.cart) {
          dispatch.checkout.updateCheckout(data.cartBuyerIdentityUpdate.cart)
        }
      } catch (error) {
        dispatch.checkout.setErrors([error as CheckoutUserError])
      } finally {
        dispatch.checkout.setIsLoading(false)
      }
    },

    async replaceCheckoutItems(
      lineItems: any[],
      state: BaseRootState
    ) {
      const { checkoutId } = state.checkout
      const client: DefaultClient<any> = createShopifyClient()

      try {
        dispatch.checkout.setIsLoading(true)
        
        // Separate items into new ones (using merchandiseId) and existing ones (with id)
        const newItems: any[] = [];
        const existingItems: any[] = [];
        lineItems.forEach(item => {
          // Check if this is a line item from the cart (has an id) or a new item
          if (item.id) {
            // Format for update (needs id)
            existingItems.push({
              id: ensureCorrectIdFormat(item.id),
              quantity: item.quantity,
              attributes: item.attributes
            });
          } else {
            // Format for add (needs merchandiseId)
            newItems.push({
              merchandiseId: stringToBase64(item.merchandiseId || item.variantId),
              quantity: item.quantity,
              attributes: item.attributes || item.customAttributes
            });
          }
        });
        
        let updatedCart: CartFieldsType | null | undefined = null;

        // Then, add new items if there are any
        if (newItems.length > 0) {
          const { data, errors } = await client.mutate<CartLinesAddResponse>({
            mutation: CartLinesAdd,
            variables: {
              cartId: checkoutId,
              lines: newItems,
            },
          });
          
          if (errors) {
            console.error("Add cart lines errors:", errors);
            dispatch.checkout.setErrors(errors);
            return;
          }
          
          if (data?.cartLinesAdd?.userErrors?.length) {
            console.error("Cart lines add errors:", data.cartLinesAdd.userErrors);
            dispatch.checkout.setErrors(data.cartLinesAdd.userErrors);
            return;
          }
          
          updatedCart = data?.cartLinesAdd?.cart;
        }
        
        // First, update existing items if there are any
        if (existingItems.length > 0) {
          const { data, errors } = await client.mutate<CartLinesUpdateResponse>({
            mutation: CartLinesUpdate,
            variables: {
              cartId: checkoutId,
              lines: existingItems,
            },
          });
          
          if (errors) {
            console.error("Update cart lines errors:", errors);
            dispatch.checkout.setErrors(errors);
            return;
          }
          
          if (data?.cartLinesUpdate?.userErrors?.length) {
            console.error("Cart lines update errors:", data.cartLinesUpdate.userErrors);
            dispatch.checkout.setErrors(data.cartLinesUpdate.userErrors);
            return;
          }
          
          updatedCart = data?.cartLinesUpdate?.cart;
        }
        
        // Update the checkout state with the latest cart data
        if (updatedCart) {
          dispatch.checkout.updateCheckout(updatedCart);
        }
      } catch (error) {
        console.error("Error updating cart lines:", error);
        dispatch.checkout.setErrors([error as CheckoutUserError]);
      } finally {
        dispatch.checkout.setIsLoading(false);
      }
    },

    async addToExistingCheckout(
      {
        cartItem,
        childCartItems = [],
      }: { cartItem: CartItem; childCartItems?: CartItem[] },
      state: BaseRootState
    ) {
      const bundleId = childCartItems.length > 0 ? uuid() : undefined
      const currentLineItems =
        state.checkout.data?.lineItems?.edges?.map(mapToLineItemInput) || []
      const lineItems: any[] = [
        ...currentLineItems,
        createCheckoutLineItem({
          ...cartItem,
          bundleId,
        }),
        ...childCartItems.map((item) =>
          createCheckoutLineItem({
            ...item,
            parentBundleId: bundleId,
          })
        ),
      ]

      dispatch.checkout.replaceCheckoutItems(lineItems)
    },

    async addToExistingCheckoutBulk(
      payload: { cartItem: CartItem; childCartItems?: CartItem[] }[],
      state: BaseRootState
    ) {
      const currentLineItems =
        state.checkout.data?.lineItems?.edges?.map(mapToLineItemInput) || []
      let lineItems: any[] = [...currentLineItems]
      payload.forEach(
        ({
          cartItem,
          childCartItems = [],
        }: {
          cartItem: CartItem
          childCartItems?: CartItem[]
        }) => {
          const bundleId = childCartItems.length > 0 ? uuid() : undefined
          lineItems.push(
            createCheckoutLineItem({
              ...cartItem,
              bundleId,
            }),
            ...childCartItems.map((item) =>
              createCheckoutLineItem({
                ...item,
                parentBundleId: bundleId,
              })
            )
          )
        }
      )

      dispatch.checkout.replaceCheckoutItems(lineItems)
    },

    async updateCheckoutItems(
      updatedCartItems: Record<string, CartItem>,
      state: BaseRootState
    ) {
      const currentLineItems = state.checkout.data?.lineItems?.edges || []
      
      const lineItems: any[] = currentLineItems
        .map((lineItem) => {
          const updatedItem = updatedCartItems[stringToBase64(lineItem.node?.id)]
          if (updatedItem) {
            const newLineItem = createCheckoutLineItem(updatedItem);
            return {id: lineItem.node.id, ...newLineItem}
          }

          return mapToLineItemInput(lineItem)
        })
        // .filter((item) => item.quantity > 0)
      dispatch.checkout.replaceCheckoutItems(lineItems)
    },

    async updateDiscount(discountCode: string | undefined, state) {
      const client: DefaultClient<any> = createShopifyClient()
      const cartId = state.checkout.checkoutId
      
      try {
        // dispatch.checkout.setIsLoading(true)
        const { data, errors } = await client.mutate<CartDiscountCodesUpdateResponse>({
          mutation: CartDiscountCodesUpdate,
          variables: {
            cartId,
            discountCodes: discountCode ? [discountCode] : [],
          },
        })

        if (errors) {
          console.error("Update discount codes errors:", errors)
          dispatch.checkout.setErrors(errors)
          return
        }

        if (data?.cartDiscountCodesUpdate?.userErrors?.length) {
          console.error("Cart discount codes update errors:", data.cartDiscountCodesUpdate.userErrors)
          dispatch.checkout.setErrors(data.cartDiscountCodesUpdate.userErrors)
          return data.cartDiscountCodesUpdate.userErrors[0];
        }

        if (data?.cartDiscountCodesUpdate?.cart) {
          dispatch.checkout.updateCheckout(data.cartDiscountCodesUpdate.cart)
          
          // Return discount information if available
          const discountCodes = data.cartDiscountCodesUpdate.cart.discountCodes || [];
          if (discountCodes.length > 0 && discountCodes[0].applicable) {
            // Return basic info - detailed discount values would need a different API call
            return { applied: true, code: discountCodes[0].code };
          }
        }
      } catch (error) {
        dispatch.checkout.setErrors([error as CheckoutUserError])
      } finally {
        // dispatch.checkout.setIsLoading(false)
      }
      return null;
    },

    createDiscount(coupon: string | undefined, state) {
      if(state?.checkout?.checkoutId && state?.quiz?.previousQuizAnswers.length && (Date.now() - parseInt(state?.quiz?.previousQuizAnswers[0]?.date) < 86400000) && 
         !state?.checkout?.data?.discountCodes?.length){
        // dispatch.checkout.createDiscount()
        dispatch.checkout.updateDiscount('QUIZ'+state.quiz.previousQuizAnswers[0]?.date)
      }
    },
    async removeCurrentDiscount(coupon: string | undefined, state) {
      if(state?.checkout?.data?.discountApplications.edges.length){
        dispatch.checkout.updateDiscount(undefined)
      }
    },

    addToCart(
      {
        cartItem,
        childCartItems,
      }: {
        cartItem: {
          type: CartItem["type"]
          productSlug: string
          contentfulProductId: string
          contentfulVariantId: string
          variantId: string
          isPreorder?: boolean
          preorderInfo?: string
        }
        childCartItems?: {
          type: CartItem["type"]
          productSlug: string
          contentfulProductId: string
          contentfulVariantId: string
          variantId: string
        }[]
      },
      state: BaseRootState
    ) {
      const payload = {
        cartItem: { ...cartItem, quantity: 1 },
        childCartItems: childCartItems?.map((item) => ({
          ...item,
          quantity: 1,
        })),
      }
      if (state.checkout.checkoutId) {
        dispatch.checkout.addToExistingCheckout(payload)
      } else {
        dispatch.checkout.createCheckout(payload)
      }
    },

    addToCartBulk(
      bulkItemList: {
        cartItem: {
          type: CartItem["type"]
          productSlug: string
          contentfulProductId: string
          contentfulVariantId: string
          variantId: string
          isPreorder?: boolean
          preorderInfo?: string
        }
        childCartItems?: {
          type: CartItem["type"]
          productSlug: string
          contentfulProductId: string
          contentfulVariantId: string
          variantId: string
        }[]
      }[],
      state: BaseRootState
    ) {
      let payload: any = []
      bulkItemList.forEach((item) => {
        payload.push({
          cartItem: { ...item.cartItem, quantity: 1 },
          childCartItems: item?.childCartItems
            ? item?.childCartItems?.map((item) => ({
                ...item,
                quantity: 1,
              }))
            : [],
        })
      })
      if (state.checkout.checkoutId) {
        dispatch.checkout.addToExistingCheckoutBulk(payload)
      } else {
        dispatch.checkout.createCheckoutBulk(payload)
      }
      
      if(state.checkout.checkoutId && state.quiz.previousQuizAnswers.length && (Date.now() - parseInt(state.quiz.previousQuizAnswers[0]?.date) < 86400000) && 
         !(state.checkout.data?.discountApplications?.edges?.length)){
        // dispatch.checkout.createDiscount()
        dispatch.checkout.updateDiscount('QUIZ'+state.quiz.previousQuizAnswers[0]?.date)
      }
    },
  }),
}
