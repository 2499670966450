import React, { ReactNode } from "react"
import {
  Box,
  BoxProps,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/core"
import { Link } from "gatsby"
import { useSelector } from "react-redux"
import { useQuery } from "@apollo/react-hooks"

import ColorDot from "../ColorDot"
import { Button } from "../Buttons"
import Body from "../typography/Body"
import Heading from "../typography/Heading"
import MiniCartItem from "./MiniCartItem"
import { formatCheckoutURL } from "../../utils/url"


import { BaseRootState } from "../../redux/store"
import { getNormalizedCustomAttributes } from "../../redux/models/checkout"
import { GetShopifyVariantPricing } from "../../shopify/graphql/queries"
import { getFormattedPrice, groupCustomizedLineItems } from "./utils"
import { IconButtonWithBadge } from "../Buttons/IconButton"
import PopoverCloseButton from "../Buttons/PopoverCloseButton"
import FormattedTitle from "../FormattedTitle"
import { isValidUserLoggedIn } from "../../utils/auth"

type CartButtonProps = BoxProps & {}

export default function CartButton({ onClick, ...props }: CartButtonProps) {
  const {
    lineItems,
    webUrl,
    bikes,
    accessories,
    customer,
    tokenExpiration,
  } = useSelector((state: BaseRootState) => ({
    lineItems: state.checkout.data?.lineItems?.edges || state.checkout.data?.lines?.edges || [],
    webUrl: formatCheckoutURL(state.checkout.data?.webUrl || state.checkout.data?.checkoutUrl || ''),
    bikes: state.bikes.bikes,
    accessories: state.bikes.accessories,
    customer: state.user.customerData,
    tokenExpiration: state.user.tokenExpiration,
  }))
  const { data } = useQuery(GetShopifyVariantPricing, {
    variables: {
      productIds: lineItems
        .map((item) => item?.node?.merchandise?.id)
        .filter((id) => !!id),
    },
  })

  const groupedLineItems = groupCustomizedLineItems(lineItems || [])

  const isLoggedIn = isValidUserLoggedIn({ accessToken: customer?.accessToken, tokenExpiration })

  return (
    <Box>
      <Popover placement="top-end" usePortal>
        <PopoverTrigger>
          <IconButtonWithBadge
            iconButtonProps={{
              "aria-label": "Cart",
              icon: "cart",
              size: "xl",
              borderRadius: props.borderRadius,
              height: "100%",
              onClick,
            }}
            countBadge={
              groupedLineItems.length > 0 ? groupedLineItems.length : undefined
            }
            {...props}
          />
        </PopoverTrigger>
        <PopoverContent
          w="23.5rem"
          px="1.25rem"
          py="2.5rem"
          zIndex={999999}
          borderRadius="0.5rem"
          boxShadow="big"
          _focus={{ outline: "none" }}
        >
          <PopoverArrow transform="rotate(45deg) translate(-0.375rem, 0.375rem)" />
          <PopoverCloseButton color="dawn" top="0.5rem" right="0.5rem" />
          <PopoverBody p={0}>
            {!lineItems?.length ? (
              <Flex
                direction="column"
                align="center"
                justify="center"
                textAlign="center"
              >
                <Heading size="6" fontWeight="bold" mb="1rem">
                  Your cart&apos;s empty
                </Heading>
                {!isLoggedIn && (
                  <Body size="xs" color="dawn">
                    Have an account? Sign in to see your cart
                  </Body>
                )}
              </Flex>
            ) : (
              <>
                <Box maxHeight="18.75rem" overflowY="auto">
                  {groupedLineItems?.map((item) => {
                    const attributes = getNormalizedCustomAttributes(
                      item.attributes
                    )
                    let header: ReactNode, detail: ReactNode
                    const type = attributes["type"]
                    const slug = attributes["productSlug"]
                    const contentfulProductId =
                      attributes["contentfulProductId"]
                    const contentfulVariantId =
                      attributes["contentfulVariantId"]

                    switch (type) {
                      case "bike":
                        const bicycle = bikes.find(
                          (bike) => bike.internalTitle === slug
                        )
                        const product = bicycle?.speeds?.find(
                          (speed) =>
                            speed?.contentful_id === contentfulProductId
                        )
                        const variant = product?.variants?.find(
                          (variant) =>
                            variant?.contentful_id === contentfulVariantId
                        )

                        header = (
                          <FormattedTitle raw={bicycle?.formattedTitle} />
                        )
                        detail = (
                          <Flex align="center" wrap="wrap">
                            {variant?.color?.hexCode && (
                              <ColorDot
                                colorCode={variant.color.hexCode}
                                mr="0.4094rem"
                              />
                            )}{" "}
                            {product?.speed && (
                              <Body as="span">
                                {product.speed} Speed
                                {!!item.accessories?.length ? ", " : ""}
                              </Body>
                            )}
                            {!!item.accessories?.length && (
                              <Body as="span">
                                {item.accessories.length} Customizations
                              </Body>
                            )}
                          </Flex>
                        )
                        break
                      case "accessory":
                        const accessory = accessories.find(
                          ({ node }) => node.internalTitle === slug
                        )?.node
                        const accVariant = accessory?.variants?.find(
                          (variant) =>
                            variant?.contentful_id === contentfulVariantId
                        )
                        header = accessory?.title
                        detail = (
                          <Flex align="center">
                            {accVariant?.color?.hexCode && (
                              <ColorDot
                                colorCode={accVariant.color.hexCode}
                                mr="0.4094rem"
                              />
                            )}
                            {accVariant?.color?.name && (
                              <Body as="span">{accVariant?.color?.name}</Body>
                            )}
                          </Flex>
                        )
                        break
                      case "warranty":
                        header = "Forever Warranty"
                        break
                    }

                    return (
                      <MiniCartItem
                        key={item.id}
                        image={item.merchandise?.image?.url}
                        header={header}
                        detail={detail}
                        price={getFormattedPrice(data?.nodes, item.merchandise?.id)}
                      />
                    )
                  })}
                </Box>
                <Flex>
                  <Box
                    as={Link}
                    d="block"
                    flex={1}
                    // @ts-ignore
                    to="/cart"
                  >
                    <Button theme="secondary" whiteSpace="pre" w="100%" px={0}>
                      View Cart{" "}
                      {groupedLineItems &&
                        groupedLineItems.length > 0 &&
                        `(${groupedLineItems?.length})`}
                    </Button>
                  </Box>
                  <Box
                    as="a"
                    d="block"
                    flex={1}
                    ml="1rem"
                    // @ts-ignore
                    href={webUrl}
                  >
                    <Button theme="primary" whiteSpace="pre" w="100%" px={0}>
                      Checkout
                    </Button>
                  </Box>
                </Flex>
              </>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
