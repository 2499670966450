import gql from "graphql-tag"
import {
  CheckoutFields,
  CheckoutFieldsType,
  CheckoutUserErrorsFields,
  CheckoutUserErrorsFieldsType,
  CustomerUserErrorsFields,
  CustomerUserErrorsFieldsType,
  CustomerAccessToken,
  CustomerAccessTokenType,
  UserErrorsFields,
  UserErrorsFieldsType,
  CustomerFields,
  CustomerFieldsType,
  CartFields,
  CartFieldsType,
  CartUserErrorsFields,
  CartUserErrorsFieldsType,
} from "./fragments"

export type CreateCheckoutResponse = {
  cartCreate: {
    cart: CartFieldsType
    userErrors: CartUserErrorsFieldsType[]
  }
}
export const CreateCheckout = gql`
  ${CartFields}
  mutation CartCreate($input: CartInput!) {
    cartCreate(input: $input) {
      cart {
        ...CartFields
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`

export type UpdateCheckoutEmailResponse = {
  checkoutEmailUpdateV2: {
    checkout: CheckoutFieldsType
    checkoutUserErrors: CheckoutUserErrorsFieldsType
  }
}
export const UpdateCheckoutEmail = gql`
  mutation CheckoutEmailUpdate($checkoutId: ID!, $email: String!) {
    checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
      checkout {
        ...CheckoutFields
      }
      checkoutUserErrors {
        ...CheckoutUserErrorsFields
      }
    }
  }
`

export type UpdateCheckoutResponse = {
  checkoutLineItemsReplace: {
    checkout: CheckoutFieldsType
    checkoutUserErrors: CheckoutUserErrorsFieldsType
  }
}
export const UpdateCheckout = gql`
  ${CheckoutFields}
  ${CheckoutUserErrorsFields}
  mutation UpdateCheckout(
    $checkoutId: ID!
    $lineItems: [CartLineInput!]!
  ) {
    checkoutLineItemsReplace(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        ...CheckoutFields
      }
      userErrors {
        ...CheckoutUserErrorsFields
      }
    }
  }
`

export const createDiscount = gql`
mutation discountCodeBasicCreate($basicCodeDiscount: DiscountCodeBasicInput!) {
  discountCodeBasicCreate(basicCodeDiscount: $basicCodeDiscount) {
    codeDiscountNode {
      codeDiscount {
        ... on DiscountCodeBasic {
          title
          codes(first: 10) {
            nodes {
              code
            }
          }
          startsAt
          endsAt
          customerSelection {
            ... on DiscountCustomerAll {
              allCustomers
            }
          }
          customerGets {
            value {
              ... on DiscountPercentage {
                percentage
              }
            }
            items {
              ... on AllDiscountItems {
                allItems
              }
            }
          }
          appliesOncePerCustomer
        }
      }
    }
    userErrors {
      field
      code
      message
    }
  }
}
`


export type ApplyDiscountResponse = {
  checkoutDiscountCodeApplyV2: {
    checkout: CheckoutFieldsType
    checkoutUserErrors: CheckoutUserErrorsFieldsType
  }
}
export const ApplyDiscount = gql`
  ${CheckoutFields}
  ${CheckoutUserErrorsFields}
  mutation ApplyDiscount($checkoutId: ID!, $discountCode: String!) {
    checkoutDiscountCodeApplyV2(
      checkoutId: $checkoutId
      discountCode: $discountCode
    ) {
      checkout {
        ...CheckoutFields
      }
      checkoutUserErrors {
        ...CheckoutUserErrorsFields
      }
    }
  }
`

export type RemoveDiscountResponse = {
  checkoutDiscountCodeRemove: {
    checkout: CheckoutFieldsType
    checkoutUserErrors: CheckoutUserErrorsFieldsType
  }
}
export const RemoveDiscount = gql`
  ${CheckoutFields}
  ${CheckoutUserErrorsFields}
  mutation RemoveDiscount($checkoutId: ID!) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        ...CheckoutFields
      }
      checkoutUserErrors {
        ...CheckoutUserErrorsFields
      }
    }
  }
`

export type CartDiscountCodesUpdateResponse = {
  cartDiscountCodesUpdate: {
    cart: CartFieldsType
    userErrors: CartUserErrorsFieldsType[]
  }
}
export const CartDiscountCodesUpdate = gql`
  ${CartFields}
  mutation CartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]!) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...CartFields
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`

export type UserLoginResponse = {
  customerAccessTokenCreate: {
    customerUserErrors: CustomerUserErrorsFieldsType
    customerAccessToken: CustomerAccessTokenType
  }
}
export const UserLogin = gql`
  ${CustomerUserErrorsFields}
  ${CustomerAccessToken}
  mutation UserLogin($email: String!, $password: String!) {
    customerAccessTokenCreate(input: { email: $email, password: $password }) {
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
      customerAccessToken {
        ...CustomerAccessToken
      }
    }
  }
`

export type MultipassLoginResponse = {
  customerAccessTokenCreate: {
    customerUserErrors: CustomerUserErrorsFieldsType
    customerAccessToken: CustomerAccessTokenType
  }
}
export const MultipassLogin = gql`
  ${CustomerAccessToken}
  ${CustomerUserErrorsFields}
  mutation MultipassLogin($multipassToken: String!) {
    customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
      customerAccessToken {
        ...CustomerAccessToken
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export type LogUserOutResponse = {
  customerAccessTokenDelete: {
    deletedAccessToken?: string
    userErrors: UserErrorsFieldsType
  }
}
export const LogUserOut = gql`
  ${UserErrorsFields}
  mutation LogUserOut($accessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $accessToken) {
      deletedAccessToken
      userErrors {
        ...UserErrorsFields
      }
    }
  }
`

export type CreateAccountResponse = {
  customerCreate: {
    customer: CustomerFieldsType
    customerUserErrors: CustomerUserErrorsFieldsType
  }
}
export const CreateAccount = gql`
  ${CustomerFields}
  ${CustomerUserErrorsFields}
  mutation CreateCustomer(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    customerCreate(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      customer {
        ...CustomerFields
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export const CustomerAddressCreate = gql`
  ${CustomerUserErrorsFields}
  mutation CustomerAddressCreate(
    $customerAccessToken: String!
    $address: MailingAddressInput!
  ) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export const CustomerAddressUpdate = gql`
  ${CustomerUserErrorsFields}
  mutation CustomerAddressUpdate(
    $customerAccessToken: String!
    $id: ID!
    $address: MailingAddressInput!
  ) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export const CustomerAddressDelete = gql`
  ${CustomerUserErrorsFields}
  mutation CustomerAddressDelete($id: ID!, $customerAccessToken: String!) {
    customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
      deletedCustomerAddressId
    }
  }
`

export const CustomerDefaultAddressUpdate = gql`
  ${CustomerUserErrorsFields}
  mutation CustomerDefaultAddressUpdate(
    $customerAccessToken: String!
    $addressId: ID!
  ) {
    customerDefaultAddressUpdate(
      customerAccessToken: $customerAccessToken
      addressId: $addressId
    ) {
      customer {
        id
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export const CustomerSendForgotPasswordEmail = gql`
  ${CustomerUserErrorsFields}
  mutation CustomerSendForgotPasswordEmail($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export type CustomerResetPasswordResponse = {
  customerReset: {
    customer: Pick<CustomerFieldsType, "id">
    customerAccessToken: CustomerAccessTokenType
    customerUserErrors: CustomerUserErrorsFieldsType[]
  }
}
export const CustomerResetPassword = gql`
  ${CustomerUserErrorsFields}
  mutation CustomResetPassword(
    $customerId: ID!
    $password: String!
    $resetToken: String!
  ) {
    customerReset(
      id: $customerId
      input: { password: $password, resetToken: $resetToken }
    ) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export type CustomerActivateResponse = {
  customerActivate: {
    customer: Pick<CustomerFieldsType, "id">
    customerAccessToken: CustomerAccessTokenType
    customerUserErrors: CustomerUserErrorsFieldsType[]
  }
}
export const CustomerActivate = gql`
  ${CustomerUserErrorsFields}
  mutation CustomerActivate(
    $customerId: ID!
    $password: String!
    $activationToken: String!
  ) {
    customerActivate(
      id: $customerId
      input: { password: $password, activationToken: $activationToken }
    ) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        ...CustomerUserErrorsFields
      }
    }
  }
`

export type CartLinesUpdateResponse = {
  cartLinesUpdate: {
    cart: CartFieldsType
    userErrors: CartUserErrorsFieldsType[]
  }
}
export const CartLinesUpdate = gql`
  ${CartFields}
  mutation CartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFields
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`

export type CartBuyerIdentityUpdateResponse = {
  cartBuyerIdentityUpdate: {
    cart: CartFieldsType
    userErrors: CartUserErrorsFieldsType[]
  }
}
export const CartBuyerIdentityUpdate = gql`
  ${CartFields}
  mutation CartBuyerIdentityUpdate($cartId: ID!, $buyerIdentity: CartBuyerIdentityInput!) {
    cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
      cart {
        ...CartFields
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`

export const CartLinesAdd = gql`
  ${CartFields}
  mutation CartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFields
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`

export interface CartLinesAddResponse {
  cartLinesAdd: {
    cart: CartFieldsType
    userErrors: CartUserErrorsFieldsType[]
  }
}
