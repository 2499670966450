import React from "react"
import { Box, BoxProps, Flex, Image, PseudoBox } from "@chakra-ui/core"
import Heading from "../typography/Heading"
import Body from "../typography/Body"

export type MiniCartItemProps = BoxProps & {
  image?: string
  header: React.ReactNode
  detail: React.ReactNode
  price: string
}

export default function MiniCartItem({
  image,
  header,
  detail,
  price,
  ...props
}: MiniCartItemProps) {
  return (
    <PseudoBox
      d="flex"
      pt="1.7169rem"
      pb="1.8856rem"
      borderBottom="1px solid"
      borderBottomColor="dividerLine"
      _last={{
        borderBottom: 0,
      }}
      {...props}
    >
      <Flex w="5.875rem" align="center" justify="center">
        <Image src={image} style={{ width: "100%" }} />
      </Flex>
      <Box pl="1.4131rem">
        <Heading size="6" mb="0.4744rem">
          {header}
        </Heading>
        <Body as="span" d="block" size="xs" color="dawn" mb="0.4744rem">
          {detail}
        </Body>
        <Body size="xs" fontWeight="semibold">
          {price}
        </Body>
      </Box>
    </PseudoBox>
  )
}
